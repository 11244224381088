import { DateTime } from "luxon"
import url from "@u/url";
import _ from "lodash"

export default {
  data () {
    return {
      vonString: this.$route.query.von || DateTime.local().minus({ years: 3 }).startOf('year').toFormat("dd.MM.yyyy"),
      bisString: this.$route.query.bis || DateTime.local().minus({ days: 1 }).startOf('day').toFormat("dd.MM.yyyy"),
    }
  },
  watch: {
    vonString (val) {
      if (val) {
        url.insertUrlParam("von", val, true)
      } else {
        url.removeUrlParam("von", true)
      }
    },
    bisString (val) {
      if (val) {
        url.insertUrlParam("bis", val, true)
      } else {
        url.removeUrlParam("bis", true)
      }
    }
  },
  computed: {
    jahre () {
      return _.range(DateTime.fromJSDate(this.von).year, DateTime.fromJSDate(this.bis).year + 1)
    },
    von: {
      get () {
        if (this.vonString) {
          let date = DateTime.fromFormat(this.vonString, "dd.MM.yyyy")
          if (date.isValid)
            return date.startOf("day").toJSDate()
          return null
        }
        return null
      },
    },
    bis: {
      get () {
        if (this.bisString) {
          let date = DateTime.fromFormat(this.bisString, "dd.MM.yyyy")
          if (date.isValid)
            return date.endOf("day").toJSDate()
          return null
        }
        return null
      },
    },
    vonDate () {
      return DateTime.fromJSDate(this.von);
    },
    bisDate () {
      return DateTime.fromJSDate(this.bis)
    },
    validVon () {
      return this.vonDate.ts <= this.bisDate.ts
    }
  }
}
