import 'babel-polyfill';

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from '@st'
import { LOAD_USER } from "@st/session/actions.type"


import { setupGlobalComponents } from "@c/setup";
import { setupPlugins } from "@p/setup";

Vue.config.productionTip = false

setupPlugins(Vue);

setupGlobalComponents(Vue);

store.dispatch(`session/${LOAD_USER}`).finally(() => {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
})
