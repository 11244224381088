<template>
  <div>
    <navbar :title="title" />
    <b-container>
      <b-row>
        <b-col cols="12" class="mb-3">
          <bx-box>
            <bx-box variant="ice">
              <b-row>
                <b-col md="3" cols="6">
                  <datepicker
                    v-model="vonString"
                    label="Von"
                    :error="!validVon"
                    :hint="validVon ? null : 'Von muss vor Bis liegen'"
                  />
                </b-col>
                <b-col md="3" cols="6">
                  <datepicker v-model="bisString" label="Bis" />
                </b-col>
                <b-col md="5" cols="11" class="mt-2 mt-md-0">
                  <b-button
                    variant="primary"
                    @click="$refs.modalKundeauswahl.show()"
                    ><i class="ifr if-customer mr-2" />Kunde wählen</b-button
                  >
                  <b-button
                    v-if="kunde"
                    pill
                    variant="dark"
                    class="ml-3 align-middle"
                    @click="setKundennummer(null)"
                  >
                    <span class="h6 align-top">{{ kunde.name }}</span>
                    <i class="fas fa-times-circle ml-2 h5 mb-0" />
                  </b-button>
                </b-col>
                <b-col md="1" cols="1" class="mt-2 mt-md-0 text-right">
                  <b-button
                    v-if="kunde"
                    variant="primary"
                    target="_blank"
                    v-b-tooltip.hover
                    title="Im Pivot ansehen"
                    :to="{
                      name: 'statistik.kundeartikel.liefermenge',
                      query: {
                        von: this.vonString,
                        bis: this.bisString,
                        filter: kunde.nummer,
                      },
                    }"
                  >
                    <i class="ifr if-layer" />
                  </b-button>
                </b-col>
                <b-col cols="6" class="mt-2">
                  <b-form-group
                    label="Mengeneinheit"
                    v-slot="{ ariaDescribedby }"
                  >
                    <b-form-radio-group
                      id="radio-einheiten"
                      v-model="einheit"
                      :options="einheiten"
                      :aria-describedby="ariaDescribedby"
                      name="radio-einheiten"
                    ></b-form-radio-group>
                  </b-form-group>
                </b-col>
              </b-row>
            </bx-box>
          </bx-box>
        </b-col>
        <b-col cols="12">
          <transition name="fade">
            <b-alert :show="showKeineDatenAltert" variant="warning">
              Keine Daten für die ausgewählte Filterung vorhanden
            </b-alert>
          </transition>
          <bx-box variant="light">
            <bx-box variant="ice">
              <b-skeleton-wrapper :loading="loading">
                <template #loading>
                  <b-card>
                    <b-skeleton-img style="height: 500px"></b-skeleton-img>
                  </b-card>
                </template>
                <div class="bg-white rounded">
                  <apexchart
                    v-if="statistik"
                    type="bar"
                    :options="chartOptions"
                    :series="chartSeries"
                  />
                </div>
              </b-skeleton-wrapper>
            </bx-box>
          </bx-box>
        </b-col>
      </b-row>
    </b-container>
    <b-modal
      ref="modalKundeauswahl"
      header-bg-variant="cosmos"
      header-text-variant="white"
      cancel-title="Abbrechen"
    >
      <template #modal-header>
        <h3>Kunde wählen</h3>
      </template>
      <b-row>
        <b-col cols="12">
          <b-form-group label="Suche">
            <b-form-input type="search" v-model="kundensuche" />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-list-group style="max-height: 600px; overflow-y: scroll">
            <b-list-group-item
              button
              v-for="k in kundenSuggestions"
              :key="k.id"
              @click="setKundennummer(k.id)"
            >
              {{ k.nummer }} {{ k.name }}
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
      <template #modal-footer>
        <b-button variant="dark" @click="$refs.modalKundeauswahl.hide()"
          >Abbrechen</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
  import api from "@a/index";
  import _ from "lodash";
  import { DateTime } from "luxon";
  import vonBisAuswahl from "@m/vonBisAuswahl";
  import url from "@u/url";
  import barchartConfig from "@/config/barchart";
  import chartcolors from "@/config/chartcolors";

  export default {
    name: "Top10Artikel",
    metaInfo() {
      const title = this.title;
      return {
        titleTemplate: () => {
          return title;
        },
      };
    },
    mixins: [vonBisAuswahl],
    props: {
      vorgangsart: {
        type: String,
        required: true,
      },
      mengenname: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        statistik: null,
        loading: true,
        kundennummer: this.$route.query.kundennummer,
        kunde: null,
        kundensuche: null,
        suchtKunde: false,
        kundenSuggestions: [],
        showKeineDatenAltert: false,
        einheit: "Stück",
      };
    },
    async created() {
      await this.ladeStatistik();
    },
    watch: {
      async von() {
        await this.ladeStatistik();
      },
      async bis() {
        await this.ladeStatistik();
      },
      async einheit() {
        await this.ladeStatistik();
      },
      kundennummer: {
        immediate: true,
        handler: async function (val) {
          if (val) {
            url.insertUrlParam("kundennummer", val);
            await this.ladeKunde();
          } else {
            url.removeUrlParam("kundennummer");
            this.kunde = null;
          }
        },
      },
      kundensuche() {
        this.debounceArtikelsuche();
      },
    },
    computed: {
      title() {
        if (!this.kunde) return "Top 10 Artikel Liefermenge";
        else return `Top 10 Artikel Liefermenge ${this.kunde.name}`;
      },
      einheiten() {
        return ["Stück", "m", "mm", "cm²"];
      },
      chartSeries() {
        return this.jahre.map((j) => {
          return {
            name: `${j}`,
            data: this.statistik.map(
              (a) => a.jahre.find((ja) => ja.jahr === j)?.menge || 0
            ),
          };
        });
      },
      chartOptions() {
        var self = this;
        return {
          chart: {
            type: "bar",
            height: 1000,
            events: {
              dataPointSelection: function (event, chartContext, config) {
                let series = config.seriesIndex;
                let dataPoint = config.dataPointIndex;

                let jahr = self.chartSeries[series].name;
                let artikelnummer = self.statistik[dataPoint].nummer;

                let von = DateTime.fromFormat(
                  `01.01.${jahr}`,
                  "dd.MM.yyyy"
                ).startOf("year");
                let bis = von.endOf("year");

                let route = self.$router.resolve({
                  name: "statistik.artikel.liefermenge12monate",
                  query: {
                    artikelnummer: artikelnummer,
                    von: von.toFormat("dd.MM.yyyy"),
                    bis: bis.toFormat("dd.MM.yyyy"),
                    kundennummer: this.kundennummer,
                  },
                });

                window.open(route.href, "_blank");
              },
            },
          },
          colors: chartcolors,
          plotOptions: barchartConfig.plotOptions,
          dataLabels: barchartConfig.dataLabels,
          stroke: barchartConfig.stroke,
          legend: barchartConfig.legend,
          xaxis: {
            categories: this.statistik
              ? this.statistik.map(
                  (a) => `${a.nummer} ${a.name} (${_.join(a.einheiten, ", ")})`
                )
              : [],
            labels: {
              maxHeight: 1000,
              hideOverlappingLabels: false,
              rotate: -65,
              style: {
                fontSize: "14px",
                cssClass: "apexcharts-xaxis-label artikel-label",
              },
            },
            title: {
              style: {
                fontSize: "20px",
              },
            },
          },
          yaxis: {
            forceNiceScale: true,
            title: {
              text: this.mengenname,
            },
            labels: {
              formatter: function (value) {
                return self.$formatAmount(value);
              },
            },
          },
          fill: {
            opacity: 1,
          },
        };
      },
    },
    methods: {
      async ladeKunde() {
        if (this.kundennummer) {
          let response = await api.kunde.getById(this.kundennummer);
          this.kunde = response.data;
        }
      },
      debounceArtikelsuche: _.debounce(async function () {
        await this.sucheKunde();
      }, 500),
      async setKundennummer(kundennummer) {
        this.kundennummer = kundennummer;
        this.$refs.modalKundeauswahl.hide();
        this.kundensuche = null;
        await this.ladeStatistik();
      },
      async sucheKunde() {
        if (this.kundensuche) {
          this.suchtKunde = true;
          try {
            let response = await api.kunde.suche(this.kundensuche, 500);
            this.kundenSuggestions = response.data;
          } finally {
            this.suchtKunde = false;
          }
        }
      },
      async ladeStatistik() {
        if (this.validVon) {
          let loader = this.$loader();
          try {
            let response = await api.statistik.artikel.getTop10(
              this.von?.getTime(),
              this.bis?.getTime(),
              this.vorgangsart,
              this.einheit,
              this.kundennummer
            );
            this.statistik = response.data;
            this.showKeineDatenAltert = this.statistik.length === 0;
          } catch (e) {
            console.log(e);
          } finally {
            loader.hide();
            this.loading = false;
          }
        }
      },
    },
  };
</script>

<style lang="scss">
</style>
