import api from "../config";
const path = "kunde"

export default {
  getById: (id) => {
    return api.get(`${path}/`, { params: { id } })
  },
  suche: (suche, count = 10) => {
    return api.get(`${path}/`, { params: { suche, count } })
  }
}
