import api from "../../config";
const path = "statistik/artikel"

var getTop10 = function (von, bis, vorgangsart, einheit, kundeId = null) {
  return api.get(
    `${path}/top10`, { params: { von, bis, vorgangsart, einheit, kundeId } }
  )
}

var getMenge12monate = function (von, bis, vorgangsart, artikelnummer, kundennummer = null, einkauf = null) {
  return api.get(
    `${path}/menge12monate`, { params: { von, bis, vorgangsart, artikelnummer, kundennummer, einkauf } }
  )
}

var getMenge = function (von, bis, vorgangsart, artikelnummer) {
  return api.get(
    `${path}/menge`, { params: { von, bis, vorgangsart, artikelnummer } }
  )
}

export default {
  getTop10: getTop10,
  getMenge12monate: getMenge12monate,
  getMenge: getMenge,
  getLiefermenge: (von, bis, artikelnummer = null) => {
    return getMenge(von, bis, 'Lieferschein', artikelnummer)
  },
}
