<template>
  <b-navbar type="dark" variant="cosmos" class="mb-3">
    <b-navbar-brand :to="{ name: 'startseite' }" class="position-absolute">
      <img height="40px" src="../assets/img/schwalm.svg" />
    </b-navbar-brand>

    <b-navbar-nav align="center" justified class="mx-auto">
      <div class="text-center w-100 text-white nav-headline">
        <h2>{{ title || "" }}</h2>
      </div>
    </b-navbar-nav>

    <b-navbar-nav align="right">
      <b-nav-item-dropdown right no-caret text="User" class="p-0">
        <template #button-content>
          <div class="user-sign text-white">
            <i class="ifr if-profil" />
            <span class="ml-2 font-italic h6">{{ username }} </span>
          </div>
        </template>
        <b-dropdown-item-button @click="logout"
          ><i class="fas fa-sign-out-alt mr-2" />Logout</b-dropdown-item-button
        >
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </b-navbar>
</template>

<script>


  import { LOGOUT } from "@st/session/actions.type"

  export default {
    name: "Navbar",
    props: {
      title: {
        type: String,
      },
    },
    computed: {
      username () {
        return this.$store.getters["session/username"];
      }
    },
    methods: {
      async logout () {
        let loader = this.$loader();
        try {
          await this.$store.dispatch(`session/${LOGOUT}`)
        } finally {
          loader.hide()
        }
      }
    }
  };
</script>

<style lang="scss">
  .nav-headline {
    color: white;
    font-family: "khand";

    h2 {
      margin-bottom: 0px;
    }
  }

  .user-sign {
    line-height: 20px;
    font-size: 24px;
  }
</style>
