import BatixIndustrialUIFramework from "batix-industrial-ui-framework"
import Loadingoverlay from "./loadingoverlay"
import Loading from 'vue-loading-overlay';
import Numberformatter from './numberformatter';
import environment from './environment';
import VueMeta from 'vue-meta';

/**
 * Setup Plugins for the Vue instance
 * @param {VueConstructor<Vue>} instance
 */
export function setupPlugins (instance) {

  instance.use(BatixIndustrialUIFramework)

  instance.use(VueMeta, { refreshOnceOnNavigation: true });

  instance.use(Loading);

  instance.use(Loadingoverlay)

  instance.use(Numberformatter)

  instance.use(environment)

}
