var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('navbar',{attrs:{"title":_vm.title}}),_c('b-container',[_c('b-row',[_c('b-col',{staticClass:"mb-3",attrs:{"cols":"12"}},[_c('bx-box',[_c('bx-box',{attrs:{"variant":"ice"}},[_c('b-row',[_c('b-col',{attrs:{"md":"3","cols":"6"}},[_c('datepicker',{attrs:{"label":"Von","error":!_vm.validVon,"hint":_vm.validVon ? null : 'Von muss vor Bis liegen'},model:{value:(_vm.vonString),callback:function ($$v) {_vm.vonString=$$v},expression:"vonString"}})],1),_c('b-col',{attrs:{"md":"3","cols":"6"}},[_c('datepicker',{attrs:{"label":"Bis"},model:{value:(_vm.bisString),callback:function ($$v) {_vm.bisString=$$v},expression:"bisString"}})],1),_c('b-col',{staticClass:"mt-2 mt-md-0",attrs:{"md":"5","cols":"11"}},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$refs.modalKundeauswahl.show()}}},[_c('i',{staticClass:"ifr if-customer mr-2"}),_vm._v("Kunde wählen")]),(_vm.kunde)?_c('b-button',{staticClass:"ml-3 align-middle",attrs:{"pill":"","variant":"dark"},on:{"click":function($event){return _vm.setKundennummer(null)}}},[_c('span',{staticClass:"h6 align-top"},[_vm._v(_vm._s(_vm.kunde.name))]),_c('i',{staticClass:"fas fa-times-circle ml-2 h5 mb-0"})]):_vm._e()],1),_c('b-col',{staticClass:"mt-2 mt-md-0 text-right",attrs:{"md":"1","cols":"1"}},[(_vm.kunde)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"variant":"primary","target":"_blank","title":"Im Pivot ansehen","to":{
                    name: 'statistik.kundeartikel.liefermenge',
                    query: {
                      von: this.vonString,
                      bis: this.bisString,
                      filter: _vm.kunde.nummer,
                    },
                  }}},[_c('i',{staticClass:"ifr if-layer"})]):_vm._e()],1),_c('b-col',{staticClass:"mt-2",attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Mengeneinheit"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-radio-group',{attrs:{"id":"radio-einheiten","options":_vm.einheiten,"aria-describedby":ariaDescribedby,"name":"radio-einheiten"},model:{value:(_vm.einheit),callback:function ($$v) {_vm.einheit=$$v},expression:"einheit"}})]}}])})],1)],1)],1)],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('transition',{attrs:{"name":"fade"}},[_c('b-alert',{attrs:{"show":_vm.showKeineDatenAltert,"variant":"warning"}},[_vm._v(" Keine Daten für die ausgewählte Filterung vorhanden ")])],1),_c('bx-box',{attrs:{"variant":"light"}},[_c('bx-box',{attrs:{"variant":"ice"}},[_c('b-skeleton-wrapper',{attrs:{"loading":_vm.loading},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('b-card',[_c('b-skeleton-img',{staticStyle:{"height":"500px"}})],1)]},proxy:true}])},[_c('div',{staticClass:"bg-white rounded"},[(_vm.statistik)?_c('apexchart',{attrs:{"type":"bar","options":_vm.chartOptions,"series":_vm.chartSeries}}):_vm._e()],1)])],1)],1)],1)],1)],1),_c('b-modal',{ref:"modalKundeauswahl",attrs:{"header-bg-variant":"cosmos","header-text-variant":"white","cancel-title":"Abbrechen"},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('h3',[_vm._v("Kunde wählen")])]},proxy:true},{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"dark"},on:{"click":function($event){return _vm.$refs.modalKundeauswahl.hide()}}},[_vm._v("Abbrechen")])]},proxy:true}])},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Suche"}},[_c('b-form-input',{attrs:{"type":"search"},model:{value:(_vm.kundensuche),callback:function ($$v) {_vm.kundensuche=$$v},expression:"kundensuche"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-list-group',{staticStyle:{"max-height":"600px","overflow-y":"scroll"}},_vm._l((_vm.kundenSuggestions),function(k){return _c('b-list-group-item',{key:k.id,attrs:{"button":""},on:{"click":function($event){return _vm.setKundennummer(k.id)}}},[_vm._v(" "+_vm._s(k.nummer)+" "+_vm._s(k.name)+" ")])}),1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }