import api from "../../config";
const path = "statistik/kunde"

export default {
  getLiefermenge: (von, bis, kundeId = null) => {
    return api.get(
      `${path}/liefermenge`, { params: { von, bis, kundeId }}
    )
  },
  getNettoumsatz: (von, bis, kundeId = null) => {
    return api.get(
      `${path}/nettoumsatz`, { params: { von, bis, kundeId }}
    )
  },
}
