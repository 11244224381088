<template>
  <div>
    <navbar :title="title" />
    <b-container>
      <b-row>
        <b-col cols="12" class="mb-3">
          <bx-box
            :header="artikel ? `${artikel.nummer} ${artikel.oberbegriff}` : ''"
            header-class="col-4 font-weight-bold"
          >
            <bx-box variant="ice">
              <b-row>
                <b-col md="2">
                  <b-button
                    variant="primary"
                    @click="$refs.modalArtikelauswahl.show()"
                    ><i class="ifr if-paket mr-2" /> Artikel wählen</b-button
                  >
                </b-col>
                <b-col md="3">
                  <datepicker
                    v-model="vonString"
                    label="Von"
                    :error="!validVon"
                    :hint="validVon ? null : 'Von muss vor Bis liegen'"
                  />
                </b-col>
                <b-col md="3">
                  <datepicker v-model="bisString" label="Bis" />
                </b-col>
                <b-col md="4">
                  <v-select
                    :options="kunden"
                    label="name"
                    :reduce="(e) => e.id"
                    v-model="selectedKunde"
                    :placeholder="adressName"
                  >
                    <template #no-options>
                      Keine Kunden zum wählen vorhanden
                    </template>
                  </v-select>
                </b-col>
              </b-row>
            </bx-box>
          </bx-box>
        </b-col>

        <b-col v-if="artikelnummer" cols="12">
          <transition name="fade">
            <b-alert :show="showKeineDatenAltert" variant="warning">
              Keine Daten für die ausgewählte Filterung vorhanden
            </b-alert>
          </transition>
          <bx-box variant="light">
            <bx-box variant="ice">
              <b-skeleton-wrapper :loading="loading">
                <template #loading>
                  <b-card>
                    <b-skeleton-img style="height: 500px"></b-skeleton-img>
                  </b-card>
                </template>
                <div class="bg-white rounded">
                  <apexchart
                    v-if="statistik"
                    type="bar"
                    height="500"
                    :options="chartOptions"
                    :series="chartSeries"
                  />
                </div>
              </b-skeleton-wrapper>
            </bx-box>
          </bx-box>
        </b-col>
      </b-row>
    </b-container>
    <b-modal
      ref="modalArtikelauswahl"
      header-bg-variant="cosmos"
      header-text-variant="white"
    >
      <template #modal-header>
        <h3>Artikel wählen</h3>
      </template>
      <b-row>
        <b-col cols="12">
          <b-form-group label="Artikelsuche">
            <b-form-input type="search" v-model="artikelsuche" />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-list-group style="max-height: 600px; overflow-y: scroll">
            <b-list-group-item
              button
              v-for="a in artikelSuggestions"
              :key="a.nummer"
              @click="setArtikelnummer(a.nummer)"
            >
              {{ a.nummer }} {{ a.oberbegriff }}
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
      <template #modal-footer>
        <b-button variant="dark" @click="$refs.modalArtikelauswahl.hide()"
          >Abbrechen</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
  import vonBisAuswahl from "@m/vonBisAuswahl";
  import api from "@a/index";
  import _ from "lodash";
  import { DateTime } from "luxon";
  import url from "@u/url";
  import barchartConfig from "@/config/barchart";
  import chartcolors from "@/config/chartcolors";

  export default {
    name: "LiefermengeArtikel12Monate",
    metaInfo() {
      const title = this.title;
      return {
        titleTemplate: () => {
          return title;
        },
      };
    },
    mixins: [vonBisAuswahl],
    props: {
      vorgangsart: {
        type: String,
        required: true,
      },
      title: {
        type: String,
        required: true,
      },
      mengenname: {
        type: String,
        required: true,
      },
      einkauf: {
        type: Boolean,
        default: false,
      },
      adressName: {
        type: String,
        default: "Kunde",
      },
    },
    data() {
      return {
        artikelnummer: this.$route.query.artikelnummer,
        statistik: null,
        loading: true,
        kunden: [],
        selectedKunde: this.$route.query.kunde
          ? parseInt(this.$route.query.kunde)
          : null,
        artikel: null,
        artikelsuche: null,
        suchtArtikel: null,
        artikelSuggestions: [],
        showKeineDatenAltert: false,
      };
    },
    async created() {
      await this.ladeStatistik();
    },
    mounted() {
      if (!this.artikelnummer) this.$refs.modalArtikelauswahl.show();
    },
    watch: {
      async von() {
        await this.ladeStatistik();
      },
      async bis() {
        await this.ladeStatistik();
      },
      async selectedKunde(val) {
        if (val) url.insertUrlParam("kunde", val);
        else url.removeUrlParam("kunde");

        await this.ladeStatistik();
      },
      artikelnummer: {
        immediate: true,
        handler: async function (val) {
          url.insertUrlParam("artikelnummer", val);
          await this.ladeArtikel();
        },
      },
      artikelsuche() {
        this.debounceArtikelsuche();
      },
    },
    computed: {
      dataOnlyIntegers() {
        return _.every(this.statistik, (s) => Number.isInteger(s.menge));
      },
      chartOptions() {
        var self = this;
        return {
          chart: {
            type: "bar",
            height: 500,
          },
          colors: chartcolors,
          plotOptions: barchartConfig.plotOptions,
          dataLabels: barchartConfig.dataLabels,
          stroke: barchartConfig.stroke,
          legend: barchartConfig.legend,
          xaxis: {
            categories: _.range(1, 13).map((m) =>
              DateTime.fromFormat(`01.${m}.1070`, "dd.M.yyyy")
                .setLocale("de")
                .toFormat("LLLL")
            ),
            title: {
              style: {
                fontSize: "9px",
              },
            },
          },
          yaxis: {
            title: {
              text: this.mengenname,
            },
            min: 0,
            forceNiceScale: true,
            labels: {
              formatter: function (value) {
                return self.dataOnlyIntegers ? value : self.$formatAmount(value);
              },
            },
          },
          fill: {
            opacity: 1,
          },
        };
      },
      chartSeries() {
        if (!this.statistik) return [];
        return this.jahre.map((j) => {
          return {
            name: `${j}`,
            data: _.range(1, 13).map((m) => {
              return (
                this.statistik.find((e) => e.jahr === j && e.monat === m)
                  ?.menge || 0
              );
            }),
          };
        });
      },
    },
    methods: {
      async ladeArtikel() {
        if (this.artikelnummer) {
          let response = await api.artikel.getByArtikelnummer(this.artikelnummer);
          this.artikel = response.data;
        }
      },
      debounceArtikelsuche: _.debounce(async function () {
        await this.sucheArtikel();
      }, 500),
      async setArtikelnummer(artikelnummer) {
        this.artikelnummer = artikelnummer;
        this.$refs.modalArtikelauswahl.hide();
        this.artikelsuche = null;
        await this.ladeStatistik();
      },
      async sucheArtikel() {
        this.suchtArtikel = true;
        try {
          let response = await api.artikel.suche(this.artikelsuche, 500);
          this.artikelSuggestions = response.data;
        } finally {
          this.suchtArtikel = false;
        }
      },
      async ladeStatistik() {
        if (this.artikelnummer && this.validVon) {
          let loader = this.$loader();
          try {
            let response = await api.statistik.artikel.getMenge12monate(
              this.von?.getTime(),
              this.bis?.getTime(),
              this.vorgangsart,
              this.artikelnummer,
              this.selectedKunde,
              this.einkauf || null
            );
            this.statistik = response.data.monate;
            this.kunden = response.data.kunden;

            this.showKeineDatenAltert = this.statistik.length === 0;
          } catch (e) {
            console.log(e);
          } finally {
            this.loading = false;
            loader.hide();
          }
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .v-select {
    .vs__dropdown-toggle {
      height: 42px;
    }
  }
</style>
