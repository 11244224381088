<template>
  <ctk-datepicker
    v-model="date"
    :id="id"
    format="DD.MM.YYYY"
    formatted="DD.MM.YYYY"
    :label="label"
    only-date
    auto-close
    no-clear-button
    color="#273a4d"
    button-now-translation="Heute"
    input-size="sm"
    :error="error"
    :hint="hint"
    />
</template>

<script>

import { v4 as uuid } from "uuid";

export default {
  name: "Datepicker",
  props: {
    label: {
      type: String
    },
    value: {
      type: String
    },
    error: {
      type: Boolean,
      default: false
    },
    hint: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      date: this.value
    }
  },
  watch: {
    value(val) {
      this.data = val
    },
    date(val) {
      if(val !== this.value)
        this.$emit('input', val)
    }
  },
  computed: {
    id() {
      return uuid();
    }
  }
}
</script>

<style>

</style>
