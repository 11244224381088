import url from "@u/url"

export default {
  data () {
    return {
      currentPage: this.$route.query.page ? parseInt(this.$route.query.page) : 1,
      perPage: this.$route.query.perpage ? parseInt(this.$route.query.perpage) : 50,
      count: 0,
      statistik: null,
      counts: [
        25,
        50,
        100
      ]
    }
  },
  watch: {
    currentPage (val) {
      url.insertUrlParam("page", val)
    },
    perPage (val) {
      url.insertUrlParam("perpage", val)
    }
  },
  computed: {
    rows () {
      return this.count > 0 ? this.count : this.statistik?.length || 0;
    },
  },
  methods: {
    setCount (elements, count) {
      this.count = count
    }
  }
}
