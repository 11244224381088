<template>
  <div id="app">
    <router-view
      v-if="$store.state.session.user !== null || $route.name === 'login'"
    />
  </div>
</template>

<script>
  import { LOAD_DATENSTAND } from "@st/actions.type";

  export default {
    name: "App",
    async created() {
      await this.$store.dispatch(LOAD_DATENSTAND);
    },
  };
</script>

<style lang="scss">
  @import "./scss/app.scss";
</style>
