import api from "../config";
const path = "artikel"

export default {
  getByArtikelnummer: (artikelnummer) => {
    return api.get(`${path}`, { params: { artikelnummer: artikelnummer } })
  },
  suche: (suche, count = 10) => {
    return api.get(`${path}`, { params: { suche, count } })
  }
}
