import { DateTime } from 'luxon';
<template>
  <div>
    <navbar title="Startseite" />
    <b-container fluid>
      <b-row>
        <b-col md="6" offset-md="3">
          <bx-module-container>
            <b-row>
              <b-col cols="12">
                <bx-module-categorie :header="statistikenHeader">
                  <b-row>
                    <b-col sm="6" md="12" lg="6" class="col-xxl-4">
                      <router-link
                        :to="{ name: 'statistik.artikel.liefermengetop10' }"
                      >
                        <bx-module
                          variant="teal"
                          left-aligned
                          title="Top 10 Artikel Liefermenge"
                          icon-class="ifr if-statistik-1"
                        ></bx-module>
                      </router-link>
                    </b-col>
                    <b-col sm="6" md="12" lg="6" class="col-xxl-4">
                      <router-link
                        :to="{ name: 'statistik.kundeartikel.liefermenge' }"
                      >
                        <bx-module
                          variant="chili"
                          left-aligned
                          title="Kunden- Artikelmatrix Liefermenge"
                          icon-class="ifr if-layer"
                        ></bx-module>
                      </router-link>
                    </b-col>
                    <b-col sm="6" md="12" lg="6" class="col-xxl-4">
                      <router-link
                        :to="{ name: 'statistik.kundeartikel.nettoumsatz' }"
                        :class="{
                          disabled: !$store.getters['session/isAdmin'],
                        }"
                      >
                        <bx-module
                          variant="fir"
                          left-aligned
                          title="Kunden- Artikelmatrix Nettoumsatz"
                          icon-class="ifr if-layer"
                          :disabled="!$store.getters['session/isAdmin']"
                        ></bx-module>
                      </router-link>
                    </b-col>
                    <b-col sm="6" md="12" lg="6" class="col-xxl-4">
                      <router-link
                        :to="{ name: 'statistik.artikelkunde.liefermenge' }"
                      >
                        <bx-module
                          variant="petrol"
                          left-aligned
                          title="Artikel-Kundenmatrix Liefermenge"
                          icon-class="ifr if-layer"
                        ></bx-module>
                      </router-link>
                    </b-col>
                    <b-col sm="6" md="12" lg="6" class="col-xxl-4">
                      <router-link
                        :to="{ name: 'statistik.artikel.liefermenge12monate' }"
                      >
                        <bx-module
                          variant="lime"
                          left-aligned
                          title="Artikel 12-Monats-Vergleich Liefermenge"
                          icon-class="ifr if-statistik-1"
                        ></bx-module>
                      </router-link>
                    </b-col>
                    <b-col sm="6" md="12" lg="6" class="col-xxl-4">
                      <router-link
                        :to="{ name: 'statistik.artikel.bestellmenge12monate' }"
                      >
                        <bx-module
                          variant="berry"
                          left-aligned
                          title="Artikel 12-Monats-Vergleich Einkauf Bestellmenge"
                          icon-class="ifr if-statistik-1"
                        ></bx-module>
                      </router-link>
                    </b-col>
                  </b-row>
                </bx-module-categorie>
              </b-col>
            </b-row>
          </bx-module-container>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import { DateTime } from "luxon";

  export default {
    name: "Startseite",
    metaInfo() {
      const title = "Startseite";
      return {
        titleTemplate: () => {
          return title;
        },
      };
    },
    computed: {
      datenstand() {
        return this.$store.state.datenstand;
      },
      statistikenHeader() {
        if (!this.datenstand) return "Statistiken";
        else
          return `Statistiken - Datenstand ${DateTime.fromMillis(
            this.datenstand
          ).toFormat("dd.MM.yyyy")}`;
      },
    },
  };
</script>

<style>
</style>
