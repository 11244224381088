import statistik from "./statistik"
import user from "./user"
import artikel from "./artikel"
import kunde from "./kunde"
import datenstand from "./datenstand"

export default {
  statistik,
  user,
  artikel,
  kunde,
  datenstand
}
