import Vue from 'vue'
import Vuex from 'vuex'
import api from "@a/index";

import session from "./session"

import { LOAD_DATENSTAND } from "./actions.type";
import { SET_DATENSTAND } from "./mutations.type";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    datenstand: null
  },
  mutations: {
    [SET_DATENSTAND] (state, val) {
      state.datenstand = val
    }
  },
  actions: {
    async [LOAD_DATENSTAND] (context) {
      context.commit(SET_DATENSTAND, (await api.datenstand.get()).data)
    },
  },
  modules: {
    session
  }
})
