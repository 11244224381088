import _ from "lodash"

import statistikRoutes from "./statistik"

const routes = [
  {
    name: "startseite",
    path: "/",
    component: () => import("@v/Startseite")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("@v/Login")
  }
]

export default _.flatten([
  routes,
  statistikRoutes
])
