export default {
  install: function(Vue) {
    Vue.mixin({
      computed: {
        $env() {
          return {
            serverUrl: process.env.VUE_APP_SERVER_URL
          }
        }
      }
    })
  }
}
