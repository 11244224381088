<template>
  <div class="form-sign-in">
    <img src="../assets/img/schwalm_logo.svg" class="w-100 mb-5" />
    <bx-box
      variant="light"
      header="Login"
      header-class="col-6 font-weight-bold h3 mb-0"
    >
      <bx-box variant="ice">
        <b-form @submit.prevent="login">
          <b-form-group :state="valid">
            <b-form-input
              name="username"
              placeholder="Benutzername"
              :disabled="processing"
              v-model="username"
              :state="valid"
            />
          </b-form-group>
          <b-form-group :invalid-feedback="error" :state="valid">
            <b-form-input
              name="password"
              type="password"
              autocomplete="current-password"
              placeholder="Passwort"
              :disabled="processing"
              v-model="password"
              :state="valid"
            />
          </b-form-group>
          <button
            class="btn btn-primary w-100"
            type="submit"
            :disabled="processing"
          >
            Login <i v-show="processing" class="fas fa-spinner fa-spin ml-2" />
          </button>
        </b-form>
      </bx-box>
    </bx-box>
  </div>
</template>

<script>

  import { LOGIN } from "@st/session/actions.type"
  import store from "@st";
  import router from "@/router";

  export default {
    name: "Login",
    data () {
      return {
        username: '',
        password: '',
        processing: false,
        valid: null,
        error: null
      }
    },
    beforeRouteEnter (to, from, next) {
      if (store.state.session.user) {
        router.replace({ name: "startseite" })
      } else {
        next();
      }
    },
    methods: {
      async login () {
        this.valid = null;
        this.error = null;
        this.processing = true;
        try {
          await this.$store.dispatch(`session/${LOGIN}`, [this.username, this.password]);

          let redirect = this.$route.query.redir;

          if (redirect) {
            window.location.href = redirect;
          } else {
            this.$router.push({ name: 'startseite' })
          }
        } catch {
          this.valid = false;
          this.error = 'Benutzername oder Passwort ist falsch!'
        } finally {
          this.processing = false;
        }
      }
    },
    computed: {
      redirect () {
        return this.$route.query.redir;
      }
    }
  };
</script>

<style lang="scss">
  .form-sign-in {
    max-width: 330px;
    padding: 1rem;
    margin: auto;
  }
</style>
