<template>
  <div>
    <navbar :title="title" />
    <b-container>
      <b-row>
        <b-col cols="12" class="mb-3">
          <bx-box>
            <bx-box variant="ice">
              <b-row>
                <b-col md="4">
                  <datepicker
                    v-model="vonString"
                    label="Von"
                    :error="!validVon"
                    :hint="validVon ? null : 'Von muss vor Bis liegen'"
                  />
                </b-col>
                <b-col md="4">
                  <datepicker v-model="bisString" label="Bis" />
                </b-col>
                <b-col md="4">
                  <b-form-input
                    v-model="filter"
                    type="search"
                    :placeholder="filterPlaceholder"
                    debounce="500"
                  />
                </b-col>
              </b-row>
            </bx-box>
          </bx-box>
        </b-col>
        <b-col cols="12">
          <bx-box variant="light">
            <bx-box variant="ice">
              <b-skeleton-wrapper :loading="loading">
                <template #loading>
                  <b-card>
                    <b-skeleton animation="wave" width="85%"></b-skeleton>
                    <b-skeleton animation="wave" width="55%"></b-skeleton>
                    <b-skeleton animation="wave" width="70%"></b-skeleton>
                  </b-card>
                </template>
                <b-row>
                  <b-col cols="8">
                    <b-pagination
                      :total-rows="rows"
                      v-model="currentPage"
                      :per-page="perPage"
                      first-number
                      last-number
                    >
                    </b-pagination>
                  </b-col>
                  <b-col cols="4" class="text-right">
                    <b-button-group>
                      <b-button
                        v-for="c in counts"
                        :variant="perPage === c ? 'primary' : 'outline-primary'"
                        :key="c"
                        @click="perPage = c"
                      >
                        {{ c }}</b-button
                      >
                    </b-button-group>
                  </b-col>
                  <b-col cols="12">
                    <b-table
                      v-if="statistik"
                      striped
                      :items="statistik"
                      :fields="fields"
                      head-variant="dark"
                      details-td-class="px-0"
                      :current-page="currentPage"
                      :per-page="perPage"
                      :filter="filter"
                      responsive
                      @sort-changed="currentPage = 1"
                      @filtered="setCount"
                    >
                      <template v-if="showGesamt" #top-row>
                        <b-td variant="success" colspan="2" class="p-1"></b-td>
                        <b-td variant="success" class="font-weight-bold py-1"
                          >Gesamt</b-td
                        >
                        <b-td
                          v-for="j in firstRow.jahre"
                          :key="j.jahr"
                          variant="success"
                          class="text-right font-weight-bold p-1"
                        >
                          {{ j.menge }}
                        </b-td>
                        <b-td
                          variant="success"
                          class="text-right font-weight-bold p-1"
                        >
                          {{ firstRow.gesamt }}
                        </b-td>
                      </template>

                      <template #cell(collapse)="row">
                        <i
                          :class="{
                            'fas fa-minus-square clickable':
                              row.detailsShowing && !row.item.loading,
                            'far fa-plus-square clickable':
                              !row.detailsShowing && !row.item.loading,
                            'fas fa-spinner fa-spin': row.item.loading,
                          }"
                          :disabled="row.item.loading"
                          @click="toggleDetails(row)"
                        />
                      </template>
                      <template #cell(name)="row">
                        <b-button
                          v-if="rootDiagramlink"
                          variant="primary"
                          size="sm"
                          target="_blank"
                          :to="`${rootDiagramlink}?kundennummer=${row.item.id}&von=${vonString}&bis=${bisString}`"
                        >
                          <i class="ifr if-statistik-1" />
                        </b-button>
                        {{ row.item.name }}
                        <span style="float: right" v-if="showRootEinheiten">
                          {{ arrayToString(row.item.einheiten) }}
                        </span>
                      </template>
                      <template #row-details="row">
                        <b-table
                          striped
                          :items="row.item.details"
                          :fields="detailFields"
                          head-variant="dark"
                        >
                          <template #cell(name)="detailRow">
                            <b-button
                              v-if="detailDiagramlink"
                              variant="primary"
                              size="sm"
                              target="_blank"
                              :to="`${detailDiagramlink}?kunde=${row.item.id}&artikelnummer=${detailRow.item.nummer}&von=${vonString}&bis=${bisString}`"
                            >
                              <i class="ifr if-statistik-1" />
                            </b-button>
                            {{ detailRow.item.name }}
                            <span
                              style="float: right"
                              v-if="showDetailEinheiten"
                            >
                              {{ arrayToString(detailRow.item.einheiten) }}
                            </span>
                          </template>
                        </b-table>
                      </template>
                    </b-table>
                  </b-col>
                  <b-col cols="8">
                    <b-pagination
                      :total-rows="rows"
                      v-model="currentPage"
                      :per-page="perPage"
                      first-number
                      last-number
                    >
                    </b-pagination>
                  </b-col>
                </b-row>
              </b-skeleton-wrapper>
            </bx-box>
          </bx-box>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  /* eslint-disable no-unexpected-multiline */

  import api from "@a";
  import vonBisAuswahl from "@m/vonBisAuswahl";
  import pagination from "@m/pagination";
  import url from "@u/url";
  import _ from "lodash";

  export default {
    name: "Pivot",
    metaInfo() {
      const title = this.title;
      return {
        titleTemplate: () => {
          return title;
        },
      };
    },
    mixins: [vonBisAuswahl, pagination],
    props: {
      title: {
        type: String,
        required: true,
      },
      root: {
        type: String,
        required: true,
      },
      detail: {
        type: String,
        required: true,
      },
      detailIdentifier: {
        type: String,
        default: "id",
      },
      kpi: {
        type: String,
        required: true,
      },
      currency: {
        type: Boolean,
        default: false,
      },
      nummerName: {
        type: String,
        default: "Nummer",
      },
      detailNummerName: {
        type: String,
        default: "Nummer",
      },
      rootDiagramlink: {
        type: String,
        default: null,
      },
      detailDiagramlink: {
        type: String,
        default: null,
      },
      filterPlaceholder: {
        type: String,
        default: "Filter",
      },
      showGesamt: {
        type: Boolean,
        default: false,
      },
      showJahre: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        filter: this.$route.query.filter || null,
        loading: true,
      };
    },
    async created() {
      await this.ladeStatistik();
    },
    watch: {
      filter(val) {
        if (this.currentPage) this.currentPage = 1;

        if (val) url.insertUrlParam("filter", val, true);
        else url.removeUrlParam("filter", true);
      },
      async von() {
        await this.ladeStatistik();
      },
      async bis() {
        await this.ladeStatistik();
      },
    },
    computed: {
      showRootEinheiten() {
        return this.kpi === "Liefermenge" && this.root === "artikel";
      },
      showDetailEinheiten() {
        return this.kpi === "Liefermenge" && this.detail === "artikel";
      },
      firstRow() {
        let firstRow = _.first(this.statistik);

        if (firstRow) {
          var self = this;
          return {
            jahre: firstRow.jahre.map((j) => {
              return {
                jahr: j.jahr,
                menge: this.currency
                  ? `${this.$formatPrice(
                      _.chain(self.statistik)
                        .sumBy((k) => k[`${j.jahr}`] || 0)
                        .value()
                    )} €`
                  : this.$formatAmount(
                      _.chain(self.statistik)
                        .sumBy((k) => k[`${j.jahr}`] || 0)
                        .value()
                    ),
              };
            }),
            gesamt: this.currency
              ? `${this.$formatPrice(
                  _.sumBy(this.statistik, (k) => k.gesamt || 0)
                )} €`
              : this.$formatAmount(_.sumBy(this.statistik, (k) => k.gesamt || 0)),
          };
        }

        return { jahre: [], gesamt: "0" };
      },
      fields() {
        if (!this.statistik) return [];

        let fields = [
          {
            key: "collapse",
            sortable: false,
            label: "",
            tdClass: "py-1 pivot-collapse",
          },
          {
            key: "nummer",
            sortable: true,
            label: this.nummerName,
            tdClass: "font-weight-bold py-1",
          },
          {
            key: "name",
            sortable: true,
            label: _.upperFirst(this.root),
            tdClass: "font-weight-bold py-1",
          },
        ];

        if (this.showJahre) {
          let firstRow = _.first(this.statistik);

          firstRow.jahre.forEach((j) => {
            fields.push({
              key: `${j.jahr}`,
              sortable: true,
              label: `${j.jahr}`,
              tdClass: "text-right font-weight-bold py-1 betrag-cell",
              thClass: "text-right betrag-cell",
              sortDirection: "desc",
              formatter: (val) => {
                return this.currency
                  ? `${this.$formatPrice(val)} €`
                  : this.$formatAmount(val);
              },
            });
          });

          fields.push({
            key: "gesamt",
            sortable: true,
            label: "Gesamt",
            tdClass: "text-right font-weight-bold py-1 betrag-cell",
            thClass: "text-right betrag-cell",
            sortDirection: "desc",
            formatter: (val) => {
              return this.currency
                ? `${this.$formatPrice(val)} €`
                : this.$formatAmount(val);
            },
          });
        }

        return fields;
      },
      detailFields() {
        if (!this.statistik) return [];

        let fields = [
          {
            key: "collapse",
            sortable: false,
            label: "",
            tdClass: "text-right py-1 pivot-collapse",
            thClass: "py-1",
          },
          {
            key: "nummer",
            sortable: false,
            label: this.detailNummerName,
            tdClass: "py-1",
            thClass: "py-1",
          },
          {
            key: "name",
            sortable: false,
            label: _.upperFirst(this.detail),
            tdClass: "py-1",
            thClass: "py-1",
          },
        ];

        let firstRow = _.first(this.statistik);

        firstRow.jahre.forEach((j) => {
          fields.push({
            key: `${j.jahr}`,
            sortable: true,
            label: `${j.jahr}`,
            tdClass: "text-right py-1 betrag-cell",
            thClass: "text-right py-1 betrag-cell",
            sortDirection: "desc",
            formatter: (val) => {
              return this.currency
                ? `${this.$formatPrice(val)} €`
                : this.$formatAmount(val);
            },
          });
        });

        fields.push({
          key: "gesamt",
          sortable: true,
          label: "Gesamt",
          tdClass: "text-right py-1 betrag-cell",
          thClass: "text-right py-1 betrag-cell",
          sortDirection: "desc",
          formatter: (val) => {
            return this.currency
              ? `${this.$formatPrice(val)} €`
              : this.$formatAmount(val);
          },
        });

        return fields;
      },
    },
    methods: {
      async ladeStatistik() {
        if (this.validVon) {
          let loader = this.$loader();
          try {
            let response = await api.statistik[this.root][`get${this.kpi}`](
              this.von?.getTime(),
              this.bis?.getTime()
            );
            response.data.forEach((e) => {
              e.details = null;
              e.loading = false;
              e.jahre.forEach((j) => {
                e[j.jahr] = j.menge;
              });
            });
            this.statistik = response.data;
          } finally {
            this.loading = false;
            loader.hide();
          }
        }
      },
      toggleDetails(row) {
        if (row.detailsShowing) {
          // if details are open, close them
          row.toggleDetails();
        } else if (row.item.details) {
          // if details already exists, show the details
          row.toggleDetails();
        } else {
          row.item.loading = true;

          api.statistik[this.root]
            [`get${this.kpi}`](
              this.von?.getTime(),
              this.bis?.getTime(),
              row.item[this.detailIdentifier]
            )
            .then((response) => {
              response.data.forEach((a) => {
                a.jahre.forEach((j) => {
                  a[j.jahr] = j.menge;
                });
              });
              row.item.details = response.data;
              row.toggleDetails();
            })
            .finally(() => {
              row.item.loading = false;
            });
        }
      },
      arrayToString(arr) {
        return _.join(arr, ", ");
      },
    },
  };
</script>

<style>
</style>
