import Navbar from "./Navbar"
import Apexchart from "vue-apexcharts";
import CtkDatepicker from "vue-ctk-date-time-picker";
import Datepicker from "../components/Datepicker";
import VueSelect from "vue-select";
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'


import "./fontawesome/config"
/**
 * Setup Global accessible Components for the Vue instance
 * @param {VueConstructor<Vue>} instance
 */
 export function setupGlobalComponents(instance) {

  instance.component('fa-icon', FontAwesomeIcon)

  instance.component('navbar', Navbar);

  instance.component('apexchart', Apexchart);

  instance.component('ctk-datepicker', CtkDatepicker);

  instance.component('datepicker', Datepicker);

  instance.component('v-select', VueSelect);
}
