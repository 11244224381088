export default [
  "#008FFB",
  "#00E396",
  "#FEB019",
  "#FF4560",
  "#775DD0",
  "#ddff57",
  "#57fff1",
  "#ff5797",
  "#5e4d54",
  "#808a75"
]
