export default {
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: "90%",
      endingShape: "rounded",
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    show: true,
    width: 2,
    colors: ["transparent"],
  },
  legend: {
    position: "top",
  },
}
