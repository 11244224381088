export default [
  {
    name: 'statistik',
    path: "/statistik",
    component: () => import("@v/statistik/Index"),
    children: [

      {
        name: "statistik.kundeartikel.liefermenge",
        path: "kundeartikel/liefermenge",
        component: () => import("@v/statistik/Pivot"),
        props: () => {
          return {
            title: 'Kunden-Artikel-Matrix nach Liefermenge',
            root: 'kunde',
            detail: 'artikel',
            kpi: 'Liefermenge',
            nummerName: "Kundennummer",
            detailNummerName: "Artikelnummer",
            rootDiagramlink: "/statistik/artikel/liefermenge/top10",
            detailDiagramlink: "/statistik/artikel/liefermenge/12monate",
            filterPlaceholder: "Kundenfilter",
          }
        }
      },
      {
        name: "statistik.kundeartikel.nettoumsatz",
        path: "kundeartikel/nettoumsatz",
        component: () => import("@v/statistik/Pivot"),
        props: () => {
          return {
            title: 'Kunden-Artikel-Matrix nach Nettoumsatz',
            root: 'kunde',
            detail: 'artikel',
            kpi: 'Nettoumsatz',
            currency: true,
            nummerName: "Kundennummer",
            detailNummerName: "Artikelnummer",
            filterPlaceholder: "Kundenfilter",
            showGesamt: true,
            showJahre: true
          }
        }
      },
      {
        name: "statistik.artikelkunde.liefermenge",
        path: "artikelkunde/liefermenge",
        component: () => import("@v/statistik/Pivot"),
        props: () => {
          return {
            title: 'Artikel-Kunden-Matrix nach Liefermenge',
            root: 'artikel',
            detail: 'kunde',
            detailIdentifier: 'nummer',
            kpi: 'Liefermenge',

            nummerName: "Artikelnummer",
            detailNummerName: "Kundennummer",
            filterPlaceholder: "Artikelfilter",
            showJahre: true
          }
        }
      },
      {
        name: "statistik.artikel.liefermengetop10",
        path: "artikel/liefermenge/top10",
        component: () => import("@v/statistik/artikel/Top10Artikel"),
        props: () => {
          return {
            vorgangsart: 'Lieferschein',
            mengenname: 'Liefermenge'
          }
        }
      },
      {
        name: "statistik.artikel.liefermenge12monate",
        path: "artikel/liefermenge/12monate",
        component: () => import("@v/statistik/artikel/Menge12Monate"),
        props: () => {
          return {
            vorgangsart: 'Lieferschein',
            title: "Liefermenge Artikel im 12-Monatsvergleich",
            mengenname: 'Liefermenge'
          }
        }
      },
      {
        name: "statistik.artikel.bestellmenge12monate",
        path: "artikel/bestellmenge/12monate",
        component: () => import("@v/statistik/artikel/Menge12Monate"),
        props: () => {
          return {
            vorgangsart: 'Bestellung',
            title: "Bestellmenge Einkauf Artikel im 12-Monatsvergleich",
            mengenname: 'Bestellmenge',
            einkauf: true,
            adressName: "Lieferant"
          }
        }
      }
    ]
  }
]
